import React from "react"
import { Layout, Hero, SEO } from "../components"
import styled from "styled-components"

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Hero>Contact</Hero>
      <ContactWrapper>
        <p>
          If you have any questions or need help for your website, please
          contact me from this contact form.
        </p>
        <section className="contact-page">
          <article className="contact-form">
            <form action="https://formspree.io/maypalap" method="POST">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="form-control"
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="form-control"
                />
                <textarea
                  name="message"
                  rows="5"
                  placeholder="Message"
                  className="form-control form-control-message"
                ></textarea>
              </div>
              <button type="submit" className="submit-btn btn">
                Send
              </button>
            </form>
          </article>
        </section>
      </ContactWrapper>
    </Layout>
  )
}
const ContactWrapper = styled.div`
  height: 65vw;
  p {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    color: rgb(0 0 0 / 77%);
    font-family: var(--ff-primary);
    margin-bottom: 3rem;
  }
`

export default ContactPage
